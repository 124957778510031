@import "assets/icons";

@tailwind base;
@tailwind components;
@tailwind utilities;

$green: #559765;
$emerald: #33896F;
$cyan: #197973;
$sapphire: #186971;
$blue: #255867;
$slate-gray: #2F4858;
$lash-white: #F2F2F2;

$yellow: #ffa000;
$red: #f25767;
$gray-blue: #e7e7f7;
$bg-gray: #f2f2f2;
$sky-blue: #87dff0;
$dark-blue: #3b4769;
$dark-green: #3a6946;
$lighter-green: #d1e6d6; //#6a79a4;
$light-green: #66a275; //#6a79a4;
$black: #050505;
$grey: #f5f5f5;
$dark-grey: #c9c9c9;
$white: #ffffff;
$grey-blue: #f5f5fc;

$run: $black;
$accepted: $yellow;
$released: $green;
$completed: $green;
$issue: $red;

accordion strong i {
  font-weight: 600;
  color: $sapphire;
}

.spacer{
  width: 100%;
  height: 1rem;
}

/* Scrollbar  */
/* width */
*:not(.scrollbar__)::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}
.offset-scroll:not(.scrollbar__)::-webkit-scrollbar {
  width: 40px;
  height: 5px;
}

/* Track */
*:not(.scrollbar__)::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.offset-scroll:not(.scrollbar__)::-webkit-scrollbar-thumb{
    background: $green;
    border:0;
    border-left:36px solid #f2f2f2;
}
/* Handle */
*:not(.scrollbar__)::-webkit-scrollbar-thumb{
  background: $green;
}

/* Handle on hover */
*:not(.scrollbar__)::-webkit-scrollbar-thumb:hover{
  background: #555;
}

svg{
  width: 24px;
  height: 24px;
}

/* Overrides */
.dashboard__content{
  overflow: hidden;
  overflow-y: scroll;
}

div#preview:has(.gcv-main-view--fullscreen) {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
