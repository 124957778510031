// Custom Theming for Angular Material
@use "@angular/material" as mat;
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";

@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

@import "./assets/styles/var";
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$Frontend-primary: mat.define-palette(mat.$green-palette);
$Frontend-accent: mat.define-palette(mat.$green-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$Frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$Frontend-theme: mat.define-light-theme((
        color: (
                primary: $Frontend-primary,
                accent: $Frontend-accent,
                warn: $Frontend-warn,
        ),
        typography: mat.define-typography-config(),
        density: 0,
));
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($Frontend-theme);

/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,700,900&display=swap");

@import "/home/me/Projects/AlphaComponents/projects/alpha/ui/styles.scss";

@font-face {
  font-family: "documents";
  src: url("./assets/styles/fonts/documents.eot");
  src: url("./assets/styles/fonts/documents.eot#iefix") format("embedded-opentype"),
  url("./assets/styles/fonts/documents.ttf") format("truetype"),
  url("./assets/styles/fonts/documents.woff") format("woff"),
  url("./assets/styles/fonts/documents.svg#documents") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "alphabroker";
  src: url("./assets/styles/fonts/alphabroker.eot");
  src: url("./assets/styles/fonts/alphabroker.eot#iefix") format("embedded-opentype"),
  url("./assets/styles/fonts/alphabroker.ttf") format("truetype"),
  url("./assets/styles/fonts/alphabroker.woff") format("woff"),
  url("./assets/styles/fonts/alphabroker.svg#alphabroker") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "alphabroker-icon";
  src: url("./assets/styles/fonts/alphabroker-icon.eot?dhyiff");
  src: url("./assets/styles/fonts/alphabroker-icon.eot?dhyiff#iefix") format("embedded-opentype"),
  url("./assets/styles/fonts/alphabroker-icon.ttf?dhyiff") format("truetype"),
  url("./assets/styles/fonts/alphabroker-icon.woff?dhyiff") format("woff"),
  url("./assets/styles/fonts/alphabroker-icon.svg?dhyiff#alphabroker-icon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

p,
span,
div,
body,
html,
ul,
li,
strong,
h1,
h2,
h3,
h4,
h5,
h6,
h7,
h8,
h9 {
  // font-family: "Raleway", sans-serif;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

a {
  color: $green;
}

html,
body,
app-root {
  height: 100%;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 30px;
}

.notice_wrapper .inner:not(.read) a {
  color: white;
  font-weight: bold;
  text-decoration: none;
}

h2 {
  font-size: 25px;
  font-weight: bolder;
  color: #424242;
  margin: 0 0 0.5em;
}

.scrollbar__,
textarea {
  overflow-y: scroll !important;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: $green;
    border: 5px solid #ffffff;
  }
}

.table_scrollbar__ {
  overflow-y: scroll !important;
  overflow-x: hidden;
  width: max-content;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0);
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: $green;
    border: 5px solid #ffffff00;
  }
}

.spacer__ {
  padding: 0.5em;
}

.cursor {
  cursor: pointer;
}

.disable-select {
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}

.truncate {
  max-width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  display: block;
  position: relative;

  & + span.hover {
    display: none;
  }

  &:hover + span.hover,
  & + span.hover:hover {
    display: block;
    background: #000;
    position: absolute;
    top: 8px;
    padding: 7px 10px 7px;
    border-radius: 6px;
    color: #fff;
    z-index: 999;
    min-width: 170px;
    text-align: center;
  }
}

.batch_details__wrapper .truncate:hover + span.hover,
.batch_details__wrapper .truncate + span.hover:hover {
  top: -6px;
}

.truncate_wrapper {
  position: relative;
  cursor: pointer;

  & span:not(.material-symbols-outlined) {
    white-space: nowrap;
    position: absolute;
    top: -4px;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    display: none;
    align-items: center;
    z-index: 999;
    padding: 0.3em 0.6em;
    border-radius: 3px;
    flex-direction: row;

    & > .material-symbols-outlined {
      font-size: 1em;
      margin-left: 1em;
    }
  }

  &:hover span {
    display: flex;
  }
}

.bookmark-active {
  color: #559765;
}

.status_icon {
  display: block;
  height: 15px;
  width: 15px;
  border-radius: 100%;
  margin-left: 1em;
  margin-top: 2px;

  &.active {
    background: $green;
  }

  &.inactive {
    background: $red;
  }
}

.batch_status {
  font-weight: bolder;
  text-transform: uppercase;

  &.submitted {
    color: $yellow;
  }

  &.draft,
  &.created,
  &.opened,
  &.run {
    font-weight: 300;
    color: $black;
  }

  &.accepted {
    color: $yellow;
  }

  &.cleared,
  &.released {
    color: $green;
  }

  &.issue {
    color: $red;
  }
}

/**
*
*   Form Fields
*
**/

.field__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > div {
    width: 60%;
  }
}

.field_input_icon {
  position: relative;
}

.field_input_icon i {
  position: absolute;
  top: 17px;
}

.field_input_icon i.right {
  right: 17px;
}

.field_input_icon i.left {
  left: 17px;
}

.field-toggle {
  position: relative;
  transition: transform 0.3s;
  transform: scale(var(--scale, 1)) translateZ(0);

  &:active {
    --scale: 0.96;
  }

  input {
    display: none;

    & + label {
      // background: #fff;
      border-radius: 9px;
      padding: 16px 0;
      min-width: 208px;
      display: block;
      cursor: pointer;
      position: relative;
      // box-shadow: -12px -12px 24px var(--light-shadow, transparent),
      //   12px 12px 24px var(--shadow, transparent);
      transition: box-shadow 0.4s;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        border-radius: inherit;
        // background: linear-gradient(160deg, #f1f4ff, #f9faff);
        // opacity: var(--gradient, 0);
        transition: opacity 0.4s;
      }

      .switch {
        position: relative;
        display: inline-block;
        z-index: 1;
        vertical-align: top;
        height: 22px;
        width: 40px;
        border-radius: 11px;
        background: #eceffc;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          border-radius: inherit;
          background: linear-gradient(90deg, $green, $dark-green);
          opacity: var(--gradient, 0);
          transition: opacity 0.4s;
        }

        .dot {
          background: #d1d6ee;
          position: absolute;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          left: -1px;
          top: -1px;
          transform: translateX(var(--offset, 0));
          transition: transform 0.4s, box-shadow 0.4s;
          box-shadow: -4px -4px 8px var(--light-shadow-2, transparent),
          4px 4px 8px var(--shadow, transparent);

          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            border-radius: inherit;
            background: linear-gradient(160deg, #f1f4ff, #f9faff);
            opacity: var(--gradient, 0);
            transition: opacity 0.4s;
          }
        }
      }

      span {
        line-height: 22px;
        font-size: 16px;
        color: var(--text, #646b8c);
        font-weight: 500;
        display: inline-block;
        vertical-align: top;
        z-index: 1;
        position: relative;
        margin-left: 12px;
        transition: color 0.4s;
        padding-right: 1.5em;
      }

      & + span {
        text-align: center;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        opacity: 0;
        font-size: 12px;
        font-weight: 500;
        color: #a6accd;
        transform: translateY(4px);
        transition: opacity 0.4s, transform 0.4s;
      }
    }

    &:not(:checked) {
      & + label {
        // pointer-events: none;
        & + span {
          opacity: 1;
          transform: translateY(12px);
        }
      }
    }

    &:checked {
      & + label {
        --offset: 18px;
        --text: #404660;
        --gradient: 1;
        --shadow: rgba(0, 6, 39, 0.1);
        --light-shadow: rgba(255, 255, 255, 0.8);
        --light-shadow-2: rgba(255, 255, 255, 0.1);
      }
    }
  }
}

.field__input {
  display: flex;
  flex-direction: column;

  > .field__error {
    padding: 1em 0 0;
    color: $red;
  }

  label {
    font-weight: bolder;
    text-transform: uppercase;
    color: $green;
    padding: 1em 0;
    font-size: 12px;
    position: relative;

    span.error {
      font-size: 10px;
      float: right;
      position: absolute;
      right: 0;
      top: 14px;
      color: #840606;
    }
  }

  &.radio {
    label {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0;
      margin: 0.3em 0;

      input {
        width: 20px;
        height: 20px;
        background-color: transparent;
      }

      p {
        text-align: left;
        padding: 0;
        padding-left: 0.8em;
        margin: 0;
        font-weight: 400;
        color: $black;
        text-transform: initial;
      }
    }
  }

  input,
  select,
  textarea {
    width: 100%;
    height: 50px;
    padding: 1em;

    background-color: $grey;

    border: 3px solid $grey;
    border-radius: 6px;
    outline: 0;

    transition: 200ms;

    &:focus {
      border: 3px solid $green;
    }

    &.invalid {
      border: 3px solid $red;
    }

    //
    //&:not {
    //  border: 3px solid $green;
    //}
  }

  textarea {
    min-height: 120px;
  }

  select {
    padding: 0.5em;
  }

  &.light {
    input,
    select {
      background-color: $white;

      border: 3px solid $white;

      &:focus {
        border: 3px solid $green;
      }

      &.invalid {
        border: 3px solid $red;
      }

      //
      //&:not {
      //  border: 3px solid $green;
      //}
    }
  }

  &.row {
    flex-direction: row;
    justify-content: center;
    align-items: center;

    > * {
      width: 100%;
    }
  }
}

.button__ {
  position: relative;
  background-color: $white;

  padding: 1em 2em;

  border-radius: 6px;
  border: 2px solid $white;
  outline: 0;

  text-transform: uppercase;
  font-weight: bolder;
  color: $green;

  cursor: pointer;

  transition: 200ms;

  &:disabled {
    opacity: 0.2;
  }

  &.right_icon {
    padding-right: 3.5em;

    i {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;

      padding: 0.5em;
      background: #000;

      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;

      background-color: transparentize($dark-green, 0.5);
    }
  }

  &.left_icon {
    padding-left: 3.5em;

    i {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;

      padding: 0.5em;
      background: #000;

      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;

      background-color: transparentize($dark-green, 0.5);
    }
  }

  &.primary {
    background-color: $green;
    border: 0;
    color: $white;

    &:hover:not(:disabled) {
      background-color: $dark-green;
      color: $white;
    }
  }

  &.warning {
    background-color: $red;
    color: $white;

    &:hover:not(:disabled) {
      background-color: $white;
      color: $red;
    }
  }

  &.transparent {
    background-color: transparent;
    border: 0;
    color: $green;
    padding: 0.5em;

    &:hover:not(:disabled) {
      color: $dark-green;
    }
  }

  &.outline {
    border: 2px solid $green;

    &:hover:not(:disabled) {
      background-color: $green;
      color: $white;
    }
  }

  &:hover:not(:disabled) {
    color: $black;
  }
}

.text-placeholder {
  display: inline-flex;
  padding: 0.5em;
  border-radius: 20px;

  background: linear-gradient(
                  270deg,
                  rgba(209, 209, 209, 0.4),
                  rgba(166, 166, 166, 0.4)
  );
  background-size: 400% 400%;

  -webkit-animation: TextPlaceHolderAnimation 46s ease infinite;
  -moz-animation: TextPlaceHolderAnimation 46s ease infinite;
  animation: TextPlaceHolderAnimation 46s ease infinite;

  &.w40 {
    width: 30px;
  }

  &.w20 {
    width: 20px;
  }

  &.w70 {
    width: 35px;
  }
}

@-webkit-keyframes TextPlaceHolderAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes TextPlaceHolderAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes TextPlaceHolderAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.more_items_wrapper {
  position: relative;

  &:hover .more_info__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  i {
    cursor: pointer;

    span {
      position: relative;
      font-family: Arial, Helvetica, sans-serif;
    }
  }

  .more_info__content {
    display: none;
    position: absolute;
    padding: 1em 0.5em 0.5em;
    background: $white;
    width: 300px;
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.4);
    flex-direction: column;
    z-index: 10;

    span {
      font-family: Arial, Helvetica, sans-serif;

      display: block;
      padding: 0.2em 1em 0.3em;
      background-color: $green;
      margin: 0 0.2em 0.5em;
      color: #ffff;
      border-radius: 30px;

      &.conflict {
        background-color: $red;
      }
    }
  }
}

i[class^="icon-"],
i[class*="icon-"] {
  font-family: "alphabroker" !important;
}

i[class^="alpha-"],
i[class*="alpha-"] {
  font-family: "alphabroker-icon" !important;
}

.icon-invoice:before {
  content: "\e92e";
}

.icon-finished:before {
  content: "\e92d";
}

.icon-bookmark:before {
  content: "\e92c";
}

.icon-plane-outline:before {
  content: "\e927";
}

.icon-plane-filled:before {
  content: "\e928";
}

.icon-boat-outline:before {
  content: "\e929";
}

.icon-freight-filled:before {
  content: "\e92a";
}

.icon-freight-outline:before {
  content: "\e92b";
}

.icon-download:before {
  content: "\e923";
}

.icon-info:before {
  content: "\e922";
}

.icon-sort-desc:before {
  content: "\e903";
}

.icon-sort-asc:before {
  content: "\e904";
}

.icon-cog:before {
  content: "\e902";
}

.icon-logout:before {
  content: "\e91d";
}

.icon-arrow_left:before {
  content: "\e91a";
}

.icon-arrow_right:before {
  content: "\e91b";
}

.icon-accounts:before {
  content: "\e918";
}

.icon-search:before {
  content: "\e91c";
}

.icon-warning:before {
  content: "\e90f";
}

.icon-account_associations:before {
  content: "\e91e";
}

.icon-add:before {
  content: "\e913";
}

.icon-batch:before {
  content: "\e905";
}

.icon-bell:before {
  content: "\e90e";
}

.icon-check:before {
  content: "\e906";
}

.icon-archive:before {
  content: "\e907";
}

.icon-document_type:before {
  content: "\e908";
}

.icon-document:before {
  content: "\e909";
}

.icon-down-arrow:before {
  content: "\e90a";
}

.icon-edit:before {
  content: "\e90b";
}

.icon-email:before {
  content: "\e90c";
}

.icon-eye:before {
  content: "\e90d";
}

.icon-house:before {
  content: "\e910";
}

.icon-bell_notice .path1:before {
  content: "\e91f";
}

.icon-bell_notice .path2:before {
  content: "\e921";
  margin-left: -0.875em;
  color: rgb(242, 87, 103);
}

.icon-pdf_file:before {
  content: "\e920";
}

.icon-printer:before {
  content: "\e914";
}

.icon-remove:before {
  content: "\e915";
}

.icon-revision_edit:before {
  content: "\e916";
}

.icon-revision_view:before {
  content: "\e917";
}

.icon-trash:before {
  content: "\e919";
}

.icon-cheveron-down:before {
  content: "\e900";
}

.icon-cheveron-left:before {
  content: "\e911";
}

.icon-cheveron-right:before {
  content: "\e912";
}

.icon-cheveron-up:before {
  content: "\e901";
}

.icon-bullet:before {
  content: "\e921";
}

.icon-process:before {
  content: "\e924";
}

.icon-checkmark:before {
  content: "\e925";
}

.icon-release:before {
  content: "\e926";
}

i[class*="icon-doc-"],
i[class^="icon-doc-"] {
  font-family: "documents" !important;
}

.icon-doc-excel .path1:before {
  content: "\e96a";
  color: rgb(233, 233, 224);
}

.icon-doc-excel .path2:before {
  content: "\e96b";
  margin-left: -1em;
  color: rgb(217, 215, 202);
}

.icon-doc-excel .path3:before {
  content: "\e96c";
  margin-left: -1em;
  color: rgb(145, 205, 160);
}

.icon-doc-excel .path4:before {
  content: "\e96d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-excel .path5:before {
  content: "\e96e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-excel .path6:before {
  content: "\e96f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-excel .path7:before {
  content: "\e970";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-zip .path1:before {
  content: "\e900";
  color: rgb(233, 233, 224);
}

.icon-doc-zip .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(217, 215, 202);
}

.icon-doc-zip .path3:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(85, 96, 128);
}

.icon-doc-zip .path4:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-zip .path5:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-zip .path6:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-zip .path7:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-zip .path8:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-document .path1:before {
  content: "\e908";
  color: rgb(233, 233, 224);
}

.icon-doc-document .path2:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(217, 215, 202);
}

.icon-doc-document .path3:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(134, 151, 203);
}

.icon-doc-document .path4:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(134, 151, 203);
}

.icon-doc-document .path5:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(134, 151, 203);
}

.icon-doc-document .path6:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(134, 151, 203);
}

.icon-doc-document .path7:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(134, 151, 203);
}

.icon-doc-document .path8:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(134, 151, 203);
}

.icon-doc-document .path9:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(134, 151, 203);
}

.icon-doc-document .path10:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(134, 151, 203);
}

.icon-doc-document .path11:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(134, 151, 203);
}

.icon-doc-document .path12:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(134, 151, 203);
}

.icon-doc-document .path13:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(134, 151, 203);
}

.icon-doc-document .path14:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(134, 151, 203);
}

.icon-doc-document .path15:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(0, 150, 230);
}

.icon-doc-document .path16:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-document .path17:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-document .path18:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-rtf .path1:before {
  content: "\e91a";
  color: rgb(233, 233, 224);
}

.icon-doc-rtf .path2:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(217, 215, 202);
}

.icon-doc-rtf .path3:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(144, 186, 225);
}

.icon-doc-rtf .path4:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-rtf .path5:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-rtf .path6:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-rtf .path7:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-rtf .path8:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-rtf .path9:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-rtf .path10:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-rtf .path11:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-rtf .path12:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-rtf .path13:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-rtf .path14:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-rtf .path15:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-rtf .path16:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-rtf .path17:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-rtf .path18:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-csv .path1:before {
  content: "\e92c";
  color: rgb(233, 233, 224);
}

.icon-doc-csv .path2:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(217, 215, 202);
}

.icon-doc-csv .path3:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(243, 111, 160);
}

.icon-doc-csv .path4:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-csv .path5:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-csv .path6:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-csv .path7:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-pdf .path1:before {
  content: "\e933";
  color: rgb(233, 233, 224);
}

.icon-doc-pdf .path2:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(217, 215, 202);
}

.icon-doc-pdf .path3:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(204, 75, 76);
}

.icon-doc-pdf .path4:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(204, 75, 76);
}

.icon-doc-pdf .path5:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-pdf .path6:before {
  content: "\e938";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-pdf .path7:before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-jpg .path1:before {
  content: "\e93a";
  color: rgb(233, 233, 224);
}

.icon-doc-jpg .path2:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(217, 215, 202);
}

.icon-doc-jpg .path3:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(243, 213, 91);
}

.icon-doc-jpg .path4:before {
  content: "\e93d";
  margin-left: -1em;
  color: rgb(38, 185, 154);
}

.icon-doc-jpg .path5:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(20, 160, 133);
}

.icon-doc-jpg .path6:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-jpg .path7:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-jpg .path8:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-mp3 .path1:before {
  content: "\e942";
  color: rgb(233, 233, 224);
}

.icon-doc-mp3 .path2:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(217, 215, 202);
}

.icon-doc-mp3 .path3:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(125, 101, 153);
}

.icon-doc-mp3 .path4:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-mp3 .path5:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-mp3 .path6:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-mp3 .path7:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-txt .path1:before {
  content: "\e949";
  color: rgb(233, 233, 224);
}

.icon-doc-txt .path2:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(217, 215, 202);
}

.icon-doc-txt .path3:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(149, 165, 165);
}

.icon-doc-txt .path4:before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-txt .path5:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-txt .path6:before {
  content: "\e94e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-txt .path7:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-txt .path8:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-txt .path9:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-txt .path10:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-txt .path11:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-txt .path12:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-txt .path13:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-txt .path14:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-txt .path15:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-txt .path16:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-txt .path17:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-txt .path18:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-avi .path1:before {
  content: "\e95b";
  color: rgb(233, 233, 224);
}

.icon-doc-avi .path2:before {
  content: "\e95c";
  margin-left: -1em;
  color: rgb(217, 215, 202);
}

.icon-doc-avi .path3:before {
  content: "\e95d";
  margin-left: -1em;
  color: rgb(215, 94, 114);
}

.icon-doc-avi .path4:before {
  content: "\e95e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-avi .path5:before {
  content: "\e95f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-avi .path6:before {
  content: "\e960";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-avi .path7:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-png .path1:before {
  content: "\e962";
  color: rgb(233, 233, 224);
}

.icon-doc-png .path2:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(101, 156, 53);
}

.icon-doc-png .path3:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(217, 215, 202);
}

.icon-doc-png .path4:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-png .path5:before {
  content: "\e966";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-png .path6:before {
  content: "\e967";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-png .path7:before {
  content: "\e968";
  margin-left: -1em;
  color: rgb(243, 213, 91);
}

.icon-doc-png .path8:before {
  content: "\e969";
  margin-left: -1em;
  color: rgb(136, 192, 87);
}

.alpha-hamburger-menu:before {
  content: "\e999";
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spin-animation {
  -webkit-animation: spin 3s linear infinite;
  -moz-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

/* Batch History */
element-batch-history {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.red {
  color: $red !important;
}

.spinner {
  margin: 100px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #333;
  height: 100%;
  width: 6px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

.dummy-input {
  transition: 500ms;
  filter: blur(5px);
  cursor: pointer;
}

.dummy-input:hover {
  filter: blur(0px);
}

i {
  position: relative;

  .info {
    position: absolute;
    display: none;
    background-color: #333;
    color: #ffffff;
    padding: 5px;
    border-radius: 6px;
    top: -5px;
    right: 20px;
    width: max-content;
  }

  &:hover {
    .info {
      display: block;
    }
  }
}

.table__item .truncate {
  width: auto !important;
}

.table__body.scrollbar__::-webkit-scrollbar-track {
  background-color: #fff0;
}

.table__body.scrollbar__::-webkit-scrollbar {
  width: 4px;
}

.table__body.scrollbar__::-webkit-scrollbar-thumb {
  border: 5px solid #559765;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

iframe[name="us-entrypoint-buttonV2"] {
  z-index: 1 !important;
}

.filter_listing .dropdownm_filter__wrapper .selected {
  background: #fff;
  border-radius: 5px;
}

.filter_listing .dropdownm_filter__wrapper .values {
  // max-height: 150px!important;
}

.gray-text {
  color: #d1d1d1;
  display: flex;
  align-items: center;

  i {
    font-size: 20px;
  }
}

.blue-text {
  color: #4056b6;
  display: flex;
  align-items: center;

  i {
    font-size: 20px;
  }
}

.green-text {
  color: #559765;
  display: flex;
  align-items: center;

  i {
    font-size: 20px;
  }
}

.field-toggle.required .state_toggler__wrapper {
  box-shadow: inset 0 0 1px 1px rgb(255 0 0) !important;
}

.table__wrapper * {
  font-size: 12px !important;
}

.size-2em {
  font-size: 20px;
}

element-list-filter .content *:not(.size-2em) {
  font-size: 11px !important;
}

span.batch_status span {
  font-size: 18px !important;
  line-height: 0 !important;
}

.mat-sort-header-button {
  text-transform: uppercase !important;
  font-weight: bolder !important;
  color: #559765 !important;
}

element-table-ng {
  position: relative;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #559765;
}

.mat-checkbox-indeterminate,
.mat-checkbox-checked {
  &.mat-primary .mat-checkbox-background {
    background-color: #559765;
  }
}

span.mat-badge-content {
  color: #fff;
  background-color: #559765;
}

us-button {
  z-index: 0;
  position: absolute;
}

p.truncate a {
  text-decoration: none;
  color: #000;
}

p.truncate a:hover {
  text-decoration: underline;
}

.mat-sort-header-content {
  text-align: left !important;
  display: flex;
  font-weight: bolder;
  color: #559765;
  align-items: center;
  text-transform: uppercase;
  font-size: 1.1em;
  font-family: Arial, Helvetica, sans-serif;
}

.mat-table thead {
  position: absolute;
  z-index: 8;
  top: 0;
  width: 100%;
}

#viewer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 40px;
  height: unset;
  width: unset;
  transition: 500ms;
}

body .gc-menu__btn-container {
  background-color: #559765 !important;
  border-left: 1px solid #f9f9f940;
}

body .gc-btn--accent {
  background-color: #559765 !important;
}

body .gc-btn--accent:not([disabled]):not(.gc-btn--disabled):hover {
  background-color: #559765;
}

body .gc-viewer-host .gc-viewer .gcv-menu .gc-menu__panel-toggle--active .gc-btn {
  background-color: #999999;
}

body .gc-btn[disabled] {
  opacity: 0.7;
}

body .gc-accent-color {
  color: #559765 !important;
}

body .gc-menu__panel-header {
  color: #559765;
}

body .gc-pdfthumbnails-outer .gc-pdfthumbnails-inner .thumbnail .label {
  color: #999999;
}

body .gc-pdfthumbnails-outer .gc-pdfthumbnails-inner .thumbnail.selected .selectdecor {
  background-color: #559765;
}

body .gc-pdfthumbnails-outer .gc-pdfthumbnails-inner .thumbnail:hover .selectdecor {
  background-color: #559765;
}

body .gcv-page-input__text {
  color: #559765;
}

#viewer:has(.gcv-main-view.gcv-main-view--fullscreen) {
  position: fixed;
  z-index: 99999;
  height: 100vh;
  width: 100vw;
}

span.material-symbols-outlined {
  font-size: 22px;
  font-weight: 300;
}

.table__groups > div:last-child:has(> .material-symbols-outlined) {
  position: absolute;
  right: 0;
  z-index: 5;
  background: #bcd2be !important;
}

.table__groups {
  padding-right: 55px;
}

.search.field_input_icon {
  height: 60px;
}

.listing .document {
  min-width: 260px;
}

element.style {
  visibility: hidden;
  height: 100%;
}

.tox.tox-tinymce, #wizywig-editor {
  height: 100% !important;
}

.tox-tinymce {
  border: 0 !important;
}

span.tox-statusbar__branding, .tox-promotion {
  display: none;

}

.notes-preview {
  padding: 2em;
  height: 89.5%;
  overflow-y: scroll;
}

.notes-preview table {
  width: 80%;
  border: 0;
}

.notes-preview table td {
  padding: .5em 0;
  border: 0;
}

element-tag-values li element-clipboard .icon-copy{
  opacity:1 !important;
}
element-tag-values element-clipboard .clicker{
  right:30px!important;
}
.size-2em {
  font-size: 20px!important;
}
.tox.tox-tinymce, #wizywig-editor {
  height: 100% !important;
  z-index: 0;
}
