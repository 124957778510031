@import url('https://fonts.googleapis.com/css2?family=Flow+Circular&display=swap');

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.flow-load *{
  font-family: 'Flow Circular', system-ui;
}

.flow-load .field, .flow-load .header, .flow-load svg, .flow-load span{
  animation: blinker 2s linear infinite;
}

@font-face {
  font-family: "documents";
  src: url("fonts/documents.eot");
  src: url("fonts/documents.eot#iefix") format("embedded-opentype"),
  url("fonts/documents.ttf") format("truetype"),
  url("fonts/documents.woff") format("woff"),
  url("fonts/documents.svg#documents") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i[class*="icon-doc-"],
i[class^="icon-doc-"] {
  font-family: "documents", serif !important;
}

.icon-doc-excel .path1:before {
  content: "\e96a";
  color: rgb(233, 233, 224);
}

.icon-doc-excel .path2:before {
  content: "\e96b";
  margin-left: -1em;
  color: rgb(217, 215, 202);
}

.icon-doc-excel .path3:before {
  content: "\e96c";
  margin-left: -1em;
  color: rgb(145, 205, 160);
}

.icon-doc-excel .path4:before {
  content: "\e96d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-excel .path5:before {
  content: "\e96e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-excel .path6:before {
  content: "\e96f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-excel .path7:before {
  content: "\e970";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-zip .path1:before {
  content: "\e900";
  color: rgb(233, 233, 224);
}

.icon-doc-zip .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(217, 215, 202);
}

.icon-doc-zip .path3:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(85, 96, 128);
}

.icon-doc-zip .path4:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-zip .path5:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-zip .path6:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-zip .path7:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-zip .path8:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-document .path1:before {
  content: "\e908";
  color: rgb(233, 233, 224);
}

.icon-doc-document .path2:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(217, 215, 202);
}

.icon-doc-document .path3:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(134, 151, 203);
}

.icon-doc-document .path4:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(134, 151, 203);
}

.icon-doc-document .path5:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(134, 151, 203);
}

.icon-doc-document .path6:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(134, 151, 203);
}

.icon-doc-document .path7:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(134, 151, 203);
}

.icon-doc-document .path8:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(134, 151, 203);
}

.icon-doc-document .path9:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(134, 151, 203);
}

.icon-doc-document .path10:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(134, 151, 203);
}

.icon-doc-document .path11:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(134, 151, 203);
}

.icon-doc-document .path12:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(134, 151, 203);
}

.icon-doc-document .path13:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(134, 151, 203);
}

.icon-doc-document .path14:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(134, 151, 203);
}

.icon-doc-document .path15:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(0, 150, 230);
}

.icon-doc-document .path16:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-document .path17:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-document .path18:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-rtf .path1:before {
  content: "\e91a";
  color: rgb(233, 233, 224);
}

.icon-doc-rtf .path2:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(217, 215, 202);
}

.icon-doc-rtf .path3:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(144, 186, 225);
}

.icon-doc-rtf .path4:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-rtf .path5:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-rtf .path6:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-rtf .path7:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-rtf .path8:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-rtf .path9:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-rtf .path10:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-rtf .path11:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-rtf .path12:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-rtf .path13:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-rtf .path14:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-rtf .path15:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-rtf .path16:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-rtf .path17:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-rtf .path18:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-csv .path1:before {
  content: "\e92c";
  color: rgb(233, 233, 224);
}

.icon-doc-csv .path2:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(217, 215, 202);
}

.icon-doc-csv .path3:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(243, 111, 160);
}

.icon-doc-csv .path4:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-csv .path5:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-csv .path6:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-csv .path7:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-pdf .path1:before {
  content: "\e933";
  color: rgb(233, 233, 224);
}

.icon-doc-pdf .path2:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(217, 215, 202);
}

.icon-doc-pdf .path3:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(204, 75, 76);
}

.icon-doc-pdf .path4:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(204, 75, 76);
}

.icon-doc-pdf .path5:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-pdf .path6:before {
  content: "\e938";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-pdf .path7:before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-jpg .path1:before {
  content: "\e93a";
  color: rgb(233, 233, 224);
}

.icon-doc-jpg .path2:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(217, 215, 202);
}

.icon-doc-jpg .path3:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(243, 213, 91);
}

.icon-doc-jpg .path4:before {
  content: "\e93d";
  margin-left: -1em;
  color: rgb(38, 185, 154);
}

.icon-doc-jpg .path5:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(20, 160, 133);
}

.icon-doc-jpg .path6:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-jpg .path7:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-jpg .path8:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-mp3 .path1:before {
  content: "\e942";
  color: rgb(233, 233, 224);
}

.icon-doc-mp3 .path2:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(217, 215, 202);
}

.icon-doc-mp3 .path3:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(125, 101, 153);
}

.icon-doc-mp3 .path4:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-mp3 .path5:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-mp3 .path6:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-mp3 .path7:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-txt .path1:before {
  content: "\e949";
  color: rgb(233, 233, 224);
}

.icon-doc-txt .path2:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(217, 215, 202);
}

.icon-doc-txt .path3:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(149, 165, 165);
}

.icon-doc-txt .path4:before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-txt .path5:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-txt .path6:before {
  content: "\e94e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-txt .path7:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-txt .path8:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-txt .path9:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-txt .path10:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-txt .path11:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-txt .path12:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-txt .path13:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-txt .path14:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-txt .path15:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-txt .path16:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-txt .path17:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-txt .path18:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-avi .path1:before {
  content: "\e95b";
  color: rgb(233, 233, 224);
}

.icon-doc-avi .path2:before {
  content: "\e95c";
  margin-left: -1em;
  color: rgb(217, 215, 202);
}

.icon-doc-avi .path3:before {
  content: "\e95d";
  margin-left: -1em;
  color: rgb(215, 94, 114);
}

.icon-doc-avi .path4:before {
  content: "\e95e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-avi .path5:before {
  content: "\e95f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-avi .path6:before {
  content: "\e960";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-avi .path7:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(200, 189, 184);
}

.icon-doc-png .path1:before {
  content: "\e962";
  color: rgb(233, 233, 224);
}

.icon-doc-png .path2:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(101, 156, 53);
}

.icon-doc-png .path3:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(217, 215, 202);
}

.icon-doc-png .path4:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-png .path5:before {
  content: "\e966";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-png .path6:before {
  content: "\e967";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-doc-png .path7:before {
  content: "\e968";
  margin-left: -1em;
  color: rgb(243, 213, 91);
}

.icon-doc-png .path8:before {
  content: "\e969";
  margin-left: -1em;
  color: rgb(136, 192, 87);
}
